/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import {
    ImageList, ImageListItem, ImageListItemBar, Modal, Paper, Typography, ButtonGroup
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import './summary.css';
import parse from 'html-react-parser';
import {
    communityContent
} from '../../app/content';
import { photos as photosLibrary } from '../../app/photos';
import * as summarySlice from './summarySlice';
import * as closerLookSlice from '../closerLook/closerLookSlice';
import { setCurrentPhoto } from '../photoView/photoViewSlice';
import { setSharedUrl } from '../map/mapSlice';
import { shareSummaryLink } from '../../app/utilities';
// import styles from './Counter.module.css';

export default function Summary() {
    const [openToast, setOpenToast] = React.useState(false);
    const summaries = useSelector(summarySlice.selectCommunitySummaries);
    const currentSummary = useSelector(summarySlice.selectCurrentSummary);
    const pressClippings = useSelector(closerLookSlice.selectPressClippings);
    const currentPressClipping = useSelector(closerLookSlice.selectCurrentPressClipping);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(summarySlice.setCommunitySummaries(communityContent));
    }, []);

    function closeSummary() {
        dispatch(summarySlice.setCurrentSummary(null));
        dispatch(setSharedUrl(null));
    }
    function handleClose(event, reason) {
        if (reason === 'backdropClick') {
            closeSummary();
        }
    }
    function shareAction(id) {
        shareSummaryLink(id);
        setOpenToast(true);
    }
    function closeToast() {
        setOpenToast(false);
    }

    return (
        <div>
            {
                currentSummary !== null ? (
                    <Modal
                        open={currentSummary !== null}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableEnforceFocus
                        disableScrollLock
                        hideBackdrop
                        className={(!isMobile || isIPad13) ? 'standard-modal-placement' : 'mobile-modal-placement'}
                        onClose={(event, reason) => handleClose(event, reason)}
                    >
                        <Paper className={(!isMobile || isIPad13) ? 'summary' : 'summary-mobile'}>
                            <IconButton size="small" onClick={() => closeSummary()} className="close-button"><CloseIcon /></IconButton>
                            <IconButton size="small" variant="outlined" className="share-button" onClick={() => shareAction(currentSummary)}><ShareIcon /></IconButton>
                            <Typography variant="h4" align="left" gutterBottom>
                                {summaries[currentSummary].name}
                            </Typography>
                            <span>
                                <div>
                                    { parse(summaries[currentSummary].summary) }
                                </div>
                                { (!isMobile || isIPad13) && 'press' in summaries[currentSummary]
                                    ? (
                                        <span>
                                            <Typography variant="h5" gutterBottom>
                                                <strong>Press</strong>
                                            </Typography>
                                            <ImageList cols={4} rowHeight={164}>
                                                {
                                                    summaries[currentSummary].press.map((pressClippingIndex) => (
                                                        <ImageListItem key={pressClippingIndex} className="press-image" onClick={() => { dispatch(closerLookSlice.setCurrentPressClipping(pressClippingIndex)); }}>
                                                            <img
                                                                src={`${pressClippings[pressClippingIndex].filePath}?w=164&h=164&fit=crop&auto=format`}
                                                                srcSet={`${pressClippings[pressClippingIndex].filePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                            />
                                                            <ImageListItemBar
                                                                title={pressClippings[pressClippingIndex].title}
                                                                subtitle={parse(pressClippings[pressClippingIndex].citation)}
                                                            />
                                                        </ImageListItem>
                                                    ))
                                                }
                                            </ImageList>
                                        </span>
                                    )
                                    : null }
                                {summaries[currentSummary].citations ? (
                                    <span>
                                        <Typography variant="h6" gutterBottom>
                                            <strong>Footnotes</strong>
                                        </Typography>
                                        <ol className="biblio">
                                            {summaries[currentSummary].citations.map((citation, index) => <li key={index}>{parse(citation)}</li>)}
                                        </ol>
                                    </span>
                                ) : null}
                            </span>
                            <Button variant="contained" onClick={() => { closeSummary(); }} className="close-button" size="small">Close</Button>
                            <br />
                            <br />
                            <Snackbar
                                open={openToast}
                                autoHideDuration={3000}
                                onClose={closeToast}
                                message="Link copied"
                            />
                        </Paper>
                    </Modal>
                ) : null
            }
        </div>
    );
}
