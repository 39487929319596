import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile, isIPad13 } from 'react-device-detect';
import {
    Slider
} from '@mui/material';

import { setYear, selectYear } from './yearSlice';
import './year.css';
import {
    availableYears
} from '../../app/constants';

export default function YearPicker() {
    const [sliderValue, setSliderValue] = React.useState(33);
    const dispatch = useDispatch();
    const currentYear = useSelector(selectYear);

    useEffect(() => {
        if (sliderValue !== currentYear) {
            const foundYear = availableYears.find((availableYear) => parseInt(availableYear.id) === currentYear);
            if (foundYear) {
                setSliderValue(foundYear.value);
            }
        }
    }, [currentYear]);

    function valueLabelFormat(value) {
        return availableYears.find((mark) => mark.value === value).label;
    }
    function evaluatedMark(selectedMark, value) {
        const determinedYear = availableYears.find((availableYear) => availableYear.value === value);
        setSliderValue(value);
        dispatch(setYear(parseInt(determinedYear.id) || determinedYear.id));
    }

    return (
        <div className={(!isMobile || isIPad13) ? 'timeline-h' : 'timeline-h-mobile'}>
            <Slider
                valueLabelFormat={valueLabelFormat}
                aria-labelledby="discrete-slider-restrict"
                step={null}
                value={sliderValue}
                valueLabelDisplay="auto"
                marks={availableYears}
                onChange={evaluatedMark}
                track={false}
            />
        </div>
    );
}
